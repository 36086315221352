import React from 'react';
import Layout from '../../components/common/Layout';
import SignupSuccess from '../../components/from/SignUp/SignUpSuccess';
import SEO from '../../components/SEO';

const SignupSuccessPage = () => (
  <Layout lang="en">
    <SEO
      title="Registration successful - Nextcloud Hosting"
      description="Please verify the registration. Check your email."
    />
    <SignupSuccess lang="en" />
  </Layout>
);

export default SignupSuccessPage;
